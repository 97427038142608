<template>
  <div>
    <div class="dropdown">
      <button
        ref="dropdownRef"
        class="btn dropdown-button dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click="focusSearchableInput"
      >
        <span :class="selectedItem ? 'active' : ''">
          {{ searchLabel }}
          <button v-if="!!selectedItem" class="btn btn-link close-button" @click="(e) => onClearSelection(true)">
            <CloseIcon class="close-button-icon" />
          </button>
          <span v-else class="chevron-icon">
            <ChevronLgIcon />
          </span>
        </span>
      </button>
      <ul ref="dropdownMenuRef" class="dropdown-menu dropdown-menu-end" :style="{ width: customWidth ?? 'auto' }">
        <li class="search-row">
          <SearchIcon class="search-icon" />
          <input
            ref="searchableInputRef"
            v-model="searchText"
            type="text"
            class="form-control"
            :placeholder="searchPlaceholder"
          />
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li v-if="!filteredOptions.length" class="no-result-wrapper">
          <div v-if="loading" v-loading="loading" class="loading" />
          <div v-else class="no-result">
            {{ $t('noSuitableResultsFound') }}
          </div>
        </li>
        <li v-for="item in filteredOptions" :key="item.id">
          <div class="dropdown-item" @click="(e) => onSelectedItem(item.id)">
            <span
              class="dropdown-item-label"
              :style="!customWidth && { maxWidth: '200px' }"
              :class="{ 'mark-selected-item': !item.selected && markSelected }"
              data-bs-toggle="tooltip"
              :title="item.name"
            >
              {{ item.name }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, toRef, computed } from 'vue';
import { ChevronLgIcon, CloseIcon, SearchIcon } from '@/assets/icons';

export default {
  components: { ChevronLgIcon, CloseIcon, SearchIcon },
  props: {
    selectedItem: {
      // selected Item shape should be: { id, name }
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    customWidth: {
      type: String,
      default: null,
    },
    markSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const dropdownMenuRef = ref();
    const dropdownRef = ref();
    const searchableInputRef = ref();
    const selectedItem = toRef(props, 'selectedItem');
    const searchText = ref('');

    const clearSearch = () => {
      searchText.value = '';
    };

    const filterNotSelected = (item) => selectedItem.value?.id !== item.id;

    const filterSearch = (item) => item.name.toLowerCase().includes(searchText.value.toLowerCase());

    const filteredOptions = computed(() => {
      const filteredSearch = props.options.filter(filterSearch);
      if (!props.markSelected) return filteredSearch.filter(filterNotSelected);

      return filteredSearch.map((item) => {
        if (selectedItem.value?.id !== item.id) {
          return { ...item, selected: true };
        }
        return item;
      });
    });

    const onClearSelection = (forceClose) => {
      if (forceClose) {
        dropdownMenuRef.value?.classList?.remove('show');
        dropdownRef.value?.classList?.remove('show');
      }

      searchText.value = '';
      emit('change', null);
    };

    const onSelectedItem = (id) => {
      searchText.value = '';

      const item = props.options.find((item) => item.id === id);
      if (!item) {
        onClearSelection();
        return;
      }

      emit('change', item);
    };

    const searchLabel = computed(() => {
      const label = props.label;
      return selectedItem?.value?.name ?? label;
    });

    const focusSearchableInput = () => {
      searchableInputRef?.value?.focus();
    };

    return {
      searchableInputRef,
      focusSearchableInput,
      searchText,
      clearSearch,
      filteredOptions,
      onSelectedItem,
      searchLabel,
      onClearSelection,
      dropdownMenuRef,
      dropdownRef,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

@import './style';

.dropdown-menu {
  padding-top: 4px;
}

.dropdown-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-result-wrapper {
  display: flex;
  justify-content: center;
  .no-result {
    margin: 24px 22px;
    text-align: center;
    text-wrap: nowrap;
    color: $typography-secondary;
  }

  .loading {
    height: 48px;
    width: 100%;
  }
}

.search-row {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.5rem;

  .form-control {
    border: none;
    box-shadow: none;
    padding: 0;
    height: 2rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    &::placeholder {
      color: $typography-secondary;
    }
  }
  .search-icon {
    color: $typography-secondary;
    height: 1rem;
    width: 1rem;
    margin: 0 0.5rem;
  }
}

.dropdown-divider {
  margin: 4px 0;
}

.mark-selected-item {
  font-weight: 500;
  color: rgb(15, 106, 227);
}
</style>
