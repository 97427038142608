<template>
  <div>
    <div class="dropdown">
      <button
        ref="dropdownRef"
        class="btn dropdown-button dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span :class="selectedItem ? 'active' : ''">
          {{ searchLabel }}
          <button v-if="!!selectedItem" class="btn btn-link close-button" @click="(e) => onClearSelection(true)">
            <CloseIcon class="close-button-icon" />
          </button>
          <span v-else class="chevron-icon">
            <ChevronLgIcon />
          </span>
        </span>
      </button>
      <ul ref="dropdownMenuRef" class="dropdown-menu dropdown-menu-end">
        <li v-for="item in options" :key="item.id">
          <div class="dropdown-item" @click="(e) => onSelectedItem(item.id)">
            <span class="dropdown-item-label" data-bs-toggle="tooltip" :title="item.name">
              {{ item.name }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, toRef, computed } from 'vue';
import { ChevronLgIcon, CloseIcon } from '@/assets/icons';

export default {
  components: { ChevronLgIcon, CloseIcon },
  props: {
    selectedItem: {
      // selected Item shape should be: { id, name }
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const dropdownMenuRef = ref();
    const dropdownRef = ref();
    const selectedItem = toRef(props, 'selectedItem');
    const onClearSelection = (forceClose) => {
      if (forceClose) {
        dropdownMenuRef.value?.classList?.remove('show');
        dropdownRef.value?.classList?.remove('show');
      }

      emit('change', null);
    };

    const onSelectedItem = (id) => {
      const item = props.options.find((item) => item.id === id);
      if (!item) {
        onClearSelection();
        return;
      }

      emit('change', item);
    };

    const searchLabel = computed(() => {
      const label = props.label;
      return selectedItem?.value?.name ?? label;
    });

    return {
      onSelectedItem,
      searchLabel,
      onClearSelection,
      dropdownMenuRef,
      dropdownRef,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

@import './style';

.dropdown-menu {
  padding-top: 4px;
}

.dropdown-item-label {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
