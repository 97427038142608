<template>
  <div class="card-cursor card pt-5 pb-5" :class="checked && 'card-checked'" @click="handleSelected">
    <div class="ps-5 pe-5 pb-5">
      <input class="form-check-input" type="radio" :checked="checked" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'" />
      <label class="form-check-label">{{ templateName }}</label>
    </div>

    <template-view
      :previewed-path="previewedPath"
      :general-tags="generalTags"
      :item-tags="itemTags"
      :reference-tags="referenceTags"
    />
  </div>
</template>

<script>
import templateView from '@/modules/document/components/templateView.vue';

export default {
  components: { templateView },
  props: {
    templateId: {
      type: String,
    },
    templateName: {
      type: String,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    previewedPath: {
      type: String,
    },
    generalTags: {
      type: Array,
      default: () => [],
    },
    itemTags: {
      type: Array,
      default: () => [],
    },
    referenceTags: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selected'],
  methods: {
    handleSelected() {
      this.$emit('selected', { templateId: this.templateId });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.form-check-label {
  font-size: $font-size-medium;
}

.card-cursor {
  cursor: pointer;
}

.card-checked {
  background-color: #f8fafb;
  border-color: $typography-secondary;
}

.card-cursor:hover {
  background-color: #f8fafb;
}
</style>
