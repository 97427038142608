<template>
  <div class="container">
    <div class="row">
      <div :style="{ 'max-width': '450px', height: '400px' }">
        <file-viewer :url="previewedPath" />
      </div>
      <div class="col">
        <structure-params-tags :general-tags="generalTags" :item-tags="itemTags" :reference-tags="referenceTags" />
      </div>
    </div>
  </div>
</template>

<script>
import StructureParamsTags from '@/modules/document/components/structureParamsTags.vue';
import { FileViewer } from '@clarityo/ui-components';

export default {
  components: { StructureParamsTags, FileViewer },
  props: {
    previewedPath: {
      type: String,
    },
    generalTags: {
      type: Array,
      default: () => [],
    },
    itemTags: {
      type: Array,
      default: () => [],
    },
    referenceTags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
