<template>
  <div class="card-cursor card pt-2 pb-2 ps-3 pe-3" :class="checked && 'card-checked'" @click="handleSelected">
    <div class="card-wrapper pb-2">
      <div class="card-title">
        <input
          class="form-check-input form-check-radio-input"
          type="radio"
          :checked="checked"
          :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
        />
        <label class="form-check-label">{{ templateName }}</label>
      </div>
      <div>
        <el-popover v-model="areStructureParamsOpen" placement="bottom-start" trigger="click">
          <div class="col">
            <structure-params-tags-new
              :general-tags="generalTags"
              :item-tags="itemTags"
              :reference-tags="referenceTags"
            />
          </div>
          <div slot="reference" @click.stop="handleStructureParamToggle">
            <InformationIcon slot="reference" :fill="'#9295A5'" :size="24" />
          </div>
        </el-popover>
      </div>
    </div>
    <ClassificationTaskDocumentViewer :document="{ filePathUrl: previewedPath }" />
  </div>
</template>

<script>
import { ref } from 'vue';
import ClassificationTaskDocumentViewer from '@/modules/document/components/ClassificationTaskDocumentViewer.vue';
import StructureParamsTagsNew from '@/modules/document/components/structureParamsTagsNew.vue';
import { InformationIcon } from '@/assets/icons';

export default {
  components: {
    ClassificationTaskDocumentViewer,
    InformationIcon,
    StructureParamsTagsNew,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
    templateName: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    previewedPath: {
      type: String,
      required: true,
    },
    generalTags: {
      type: Array,
      default: () => [],
    },
    itemTags: {
      type: Array,
      default: () => [],
    },
    referenceTags: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selected', 'toggleStructureParams'],
  setup(props, { emit }) {
    const areStructureParamsOpen = ref(false);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const closeStructureParams = () => {
      areStructureParamsOpen.value = false;
    };
    const handleStructureParamToggle = async () => {
      await sleep(0); // just to wait till the queue will be empty and ref was updated
      if (areStructureParamsOpen.value) emit('toggleStructureParams');
    };

    const handleSelected = () => {
      emit('selected', { templateId: props.templateId });
    };

    return { handleStructureParamToggle, handleSelected, areStructureParamsOpen, closeStructureParams };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.card-title {
  display: flex;
  align-items: center;
}

.form-check-label {
  font-size: $font-size-medium;
  font-weight: 500;
  margin: 0;
}

.form-check-radio-input {
  margin-top: 0;
}

.card-cursor {
  cursor: pointer;
}

.card-checked {
  background-color: #f8fafb;
  border-color: $typography-secondary;
}

.card-cursor:hover {
  background-color: #f8fafb;
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
